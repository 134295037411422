import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { StorageService } from '../shared/storage.service';
import { UtilsService } from '../shared/utils.service';
import { DefaultAccountComponent } from '../default-account/default-account.component';
import { ApiRequestService } from '../shared/api-request.service';
import { UserMessagesService } from '../user/user-messages/user-messages.service';
import { AppcuesService } from '../services/appcues.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  email: string;
  password: string;
  passwordFieldType: string = 'password';

  private redirect = 'dashboard';

  inProgress: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utils: UtilsService,
    private app: AppService,
    private storage: StorageService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    private userMessagesService: UserMessagesService,
    private appcuesService: AppcuesService
  ) {}

  ngOnInit() {
    // Get the redirect route param.
    this.redirect = this.activatedRoute.snapshot.params['redirect'];
    // Check for an alternative redirect query param.
    if ( !this.redirect ) {
      this.redirect = this.activatedRoute.snapshot.queryParams['redirect'];
    }
    // Automatically sign the user in if the user did not sign out previously.
    this.autoValidateUserAuthentication();
  }

  /**
   * Automatically validate the user's authentication if the user did not sign out.
   * The user's auth token is stored in the local storage.
   */
  autoValidateUserAuthentication() {
    // Get the auth token from the local storage.
    const apiToken = this.storage.get('AuthToken');
    // Terminate early if the auth token is not set.
    if ( !apiToken ) {
      return;
    }
    // Terminate early if another sign-in process is in progress.
    if ( this.inProgress ) {
      return;
    }
    // Set it as in progress.
    this.inProgress = true;
    // Make the API request to validate the user's auth token.
    this.api.makeRequest('post', 'v2/api-token-validation', {
      api_token: apiToken
    })
    .then((response) => {
      this.inProgress = false;
      this.handleTokenResponse(response);
    })
    .catch((errors) => {
      this.inProgress = false;
      this.utils.handleAPIErrors(errors);
    });
  }

  /**
   * The user has submitted the sign-in form.
   * @param form The NgForm object.
   */
  onSubmit(form: NgForm) {
    // Validate the form inputs.
    if ( !form.valid ) {
      this.utils.showModal('Error Message', 'Please make sure you enter all required fields with valid formats.');
      return;
    }
    // Check if another sign-in process is in progress.
    if ( this.inProgress ) {
      return;
    }
    // Set it as in progress.
    this.inProgress = true;
    // Make the API request to authenticate the user.
    this.api.makeRequest('post', 'v2/login', {
      'email': this.email,
      'password': this.password
    })
    .then((response) => {
      this.inProgress = false;
      this.handleTokenResponse(response);
    })
    .catch((errors) => {
      this.inProgress = false;
      this.utils.handleAPIErrors(errors);
    });
  }

  /**
   * This is used to handle the user's sign-in response.
   * Similar method defined in reset-password.component.ts
   * @param response The response object from the API.
   * @private
   */
  private handleTokenResponse(response) {
    // Check if we have an account and user in the response data.
    if ( !response.account || !response.user ) {
      this.utils.showToast('Your account or user is invalid. Please try again.');
      this.inProgress = false;
      return;
    }

    // Set the user's authenticated status to true.
    this.app.isAuthenticated = true;

    // Store the user's auth token.
    this.storage.set('AuthToken', response.api_token);

    // Store the user, account and user account link data.
    this.app.user.apply(response.user);
    this.app.account.apply(response.account);
    this.app.userAccountLink.apply(response.user_account_link);

    this.appcuesService.onIdentifyUser(this.app.user.email, {
      contact_person: this.app.user.contact_person,
      email: this.app.user.email,
      mobile: this.app.user.mobile,
      trade: this.app.user.trade,
      address: this.app.user.address,
      suburb: this.app.user.suburb,
      city: this.app.user.city,
      postal_code: this.app.user.postal_code,
      country: this.app.user.country
    });

    this.appcuesService.onIdentifyGroup(this.app.account.name, {
      name: this.app.account.name,
      role: this.app.userAccountLink.permissions,
      industry: this.app.account.industry,
      address: this.app.account.address,
      suburb: this.app.account.suburb,
      city: this.app.account.city,
      postal_code: this.app.account.postal_code,
      country: this.app.account.country
    });

    // Set the in progress status to false.
    this.inProgress = false;

    // Get a copy of the query params that were preserved.
    let redirect_params = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams));
    if ( typeof redirect_params['redirect'] !== 'undefined' ) {
      // Remove redirect from the preserved query params.
      delete redirect_params['redirect'];
    }

    // Redirect the user to the dashboard or which ever page the user previously visited.
    // Include the preserved query params.
    this.utils.redirectPage(this.redirect ? [this.redirect] : ['/dashboard'], redirect_params).then(() => {
      // Check if the user updated their employer link in the last year.
      this.checkDefaultAccount();
      // Get the user's messages meta. This includes the number of unread messages.
      this.userMessagesService.getMessagesMeta();
    });
  }

  /**
   * Displays a dialog to the user if the default account needs to be updated.
   */
  private checkDefaultAccount() {
    // Check if the user has updated their default account in the last year.
    if ( !this.shouldUpdateAccount(this.app.user.account_id_updated_at) ) {
      return;
    }
    // Show a message to the user to validate their default employer.
    this.utils.showToast('Please validate your default employer.');
    // Show the default account selection dialog.
    this.utils.showComponentDialog(DefaultAccountComponent, {}, { width: '500px' });
  }

  /**
   * Does the users default account need updating?
   *
   * @param updatedAt Unix timestamp of when the account was last set.
   */
  private shouldUpdateAccount(updatedAt: number): boolean {
    // Return true if the user never updated their default account.
    if ( !updatedAt ) {
      return true;
    }
    // Check if the user updated their default account in the last year.
    const now = new Date().getTime();
    const milliseconds = now - updatedAt * 1000;
    const years = milliseconds / 1000 / 60 / 60 / 24 / 30.41 / 12;
    return years >= 1;
  }


  /**
   * Toggles the visibility of the password field.
   *
   * @returns {void}
   */
  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}
