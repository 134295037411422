import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AppcuesService {

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private utils: UtilsService
  ) { }

  /**
   * Initializes the component after Angular has fully initialized the component's view.
   * Subscribes to router events and triggers Appcues page tracking on each NavigationEnd event,
   * if Appcues is defined. This is called in src/app/app.component.ts.
   *
   * @return {void} Does not return a value.
   */
  onInit(): void {
    if ( typeof Appcues !== 'undefined' ) {
      this.subscriptions.push(this.router.events.subscribe((event): void => {
        if ( event instanceof NavigationEnd ) {
          Appcues?.page();
        }
      }));
    }
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Cleans up all active subscriptions to prevent memory leaks by unsubscribing
   * from each subscription in the subscriptions array and clearing the array.
   *  This is called in src/app/app.component.ts.
   *
   * @return {void} No return value as this method performs cleanup operations.
   */
  onDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * Identifies a user within the Appcues platform by associating their unique user ID with specific properties.
   *
   * @param {string} userId - The unique identifier for the user.
   * @param {Record<string, unknown>} properties - A collection of additional properties to be associated with the user.
   * @return {void} This method does not return a value.
   */
  onIdentifyUser(userId: string, properties: Record<string, unknown> = {}): void {
    if ( typeof Appcues !== 'undefined' && userId ) {
      Appcues?.identify(userId, {
        ...properties,
        is_production: this.utils.isProduction(),
        version: this.utils.getAppVersion(),
        environment: this.utils.getEnvModeAsText(),
        current_date: new Date().toISOString()
      });
    }
  }

  /**
   * Identifies a group and sends group-related data to the Appcues service.
   *
   * @param {string} groupId - The unique identifier for the group.
   * @param {Record<string, unknown>} properties - A record containing additional properties to attach to the group.
   * @return {void} This method does not return a value.
   */
  onIdentifyGroup(groupId: string, properties: Record<string, unknown> = {}): void {
    if ( typeof Appcues !== 'undefined' && groupId ) {
      Appcues?.group(groupId, {
        ...properties,
        is_production: this.utils.isProduction(),
        version: this.utils.getAppVersion(),
        environment: this.utils.getEnvModeAsText(),
        current_date: new Date().toISOString()
      })
    }
  }

  /**
   * Tracks a custom event with optional properties.
   * The event is recorded if the Appcues object is defined and a valid event name is provided.
   *
   * @param {string} eventName - The name of the event to be tracked. Must be a non-empty string.
   * @param {Record<string, unknown>} [properties={}] - An optional object containing additional metadata or attributes to include with the event.
   * @return {void} This method does not return a value.
   */
  onTrackEvent(eventName: string, properties: Record<string, unknown> = {}): void {
    if ( typeof Appcues !== 'undefined' && eventName ) {
      Appcues?.track(eventName, properties);
    }
  }

  /**
   * Resets the state of the Appcues library if it is defined.
   * This method checks for the existence of the Appcues object and invokes its reset method if available.
   *
   * @return {void} Does not return any value.
   */
  onReset(): void {
    if ( typeof Appcues !== 'undefined' ) {
      Appcues?.reset();
    }
  }
}
