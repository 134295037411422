<div class="container-fluid">

  <form #f="ngForm" class="p-3">

    <div class="row">
      <div class="col">

        <h5>After-hours Notifications</h5>

        <mat-checkbox [(ngModel)]="site.enable_after_hours_notification" name="enable_after_hours_notification">
          Enable after-hours notifications for this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.
        </mat-checkbox>

        <p>
          <small>
            <b>When this option is enabled</b>, a few notifications will be sent after the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} closing time elapsed. <br>
            30 minutes: Onsite users are prompted to sign out. <br>
            60 minutes: Onsite users are prompted again to sign out. <br>
            5 - 75 minutes (as selected by Admin): {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} managers or administrators are notified of all onsite users.
          </small>
        </p>

        <mat-form-field class="full-width" style="max-width: 600px;">
          <mat-select
            placeholder="Select duration of time that After-hours notifications are sent after the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} closing time."
            [(ngModel)]="site.after_hours_notification_duration"
            name="after_hours_notification_duration"
            id="after_hours_notification_duration"
            required>
            <mat-option *ngFor="let after_hours_notification_duration_option of after_hours_notification_duration_options" [value]="after_hours_notification_duration_option">
              {{ after_hours_notification_duration_option }} Minutes
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p>
          <small>
            {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} managers or administrators will be notified {{ site.after_hours_notification_duration }} minutes after {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} closing time. The duration is adjustable via the dropdown selection.
          </small>
        </p>

      </div>
    </div>

    <div class="row">
      <div class="col">

        <h5>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Entry Proximity Alert</h5>

        <mat-checkbox [(ngModel)]="site.is_proximity_check_enabled" name="is_proximity_check_enabled">
          Enable 1km Proximity Check for Manual {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} Sign-Ins via the Mobile App.
        </mat-checkbox>

        <p>
          <small>
            When enabled, if a user manually signs in from the mobile app and is more than 1km from the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}, a silent notification is triggered (sign-ins still proceed regardless of location). If the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} address cannot be decoded by Google Maps (e.g. the address does not exist yet), the alert will not trigger. Adding a geofence to the {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} will resolve that issue.
          </small>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <h5>Enable/Disable Auto {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Sign-out</h5>

        <mat-checkbox [(ngModel)]="site.qr_code_auto_signout_enabled" name="qr_code_auto_signout_enabled">
          Enable Auto Sign-out for this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} when QR codes of other {{ utils.getLangTerm('parent-child-sites-combined.plural', 'Sites') }} are scanned.
        </mat-checkbox>

        <p>
          <small>
            <b>When this option is enabled</b> within this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}, scanning a QR code of another {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} will sign the user out of this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.<br>
            <b>When this option is disabled</b> within this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}, scanning a QR code of another {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} will not sign the user out of this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}.
          </small>
        </p>

      </div>
    </div>

    <div class="row">
      <div class="col">

        <h5>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Access Options</h5>

        <mat-form-field class="full-width" style="max-width: 600px;">
          <mat-select placeholder="{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Access Options" [(ngModel)]="site.site_access_option" name="site_access_option" required id="site_access_option">
            <mat-option value="signin:only">Sign-in with acknowledgement.</mat-option>
            <mat-option value="signin:induction">Sign-in with acknowledgement and Hazards & Risks.</mat-option>
            <mat-option value="signin:all">Sign-in with acknowledgement and all {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} information.</mat-option>
          </mat-select>
        </mat-form-field>

        <p>
          <small>
            The selected {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} access option will apply to all users (scanning QR codes) that are not in your connected network.
          </small>
        </p>

      </div>
    </div>

    <div class="row">
      <div class="col">

        <h5>{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }} Risk Assessment</h5>

        <mat-checkbox [(ngModel)]="site.show_site_risk_assessment" name="show_site_risk_assessment">
          Show the risk level in the {{ utils.getAppName() }} mobile app for this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.
        </mat-checkbox>

        <p>
          <small>
            <b>When this option is enabled</b>, the color coded risk level will be shown to the users on the {{ utils.getAppName() }} mobile app for this {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }}.
          </small>
        </p>

      </div>
    </div>

    <div class="row">
      <div class="col">

        <button
          mat-raised-button
          [disabled]="!f.valid || inProgress"
          (click)="onSave(f)"
          type="button"
          color="primary"
          class="save-button mr-3">
          <mat-icon>save</mat-icon> Save
        </button>

      </div>
    </div>
  </form>
</div>

<ng-template #showLoader>
  <div class="d-flex justify-content-center align-items-center full-height">
    <table>
      <tr>
        <td class="d-flex justify-content-center">
          <mat-progress-spinner [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
        </td>
      </tr>
      <tr>
        <td class="d-flex justify-content-center">
          <p>Please wait while we get the settings...</p>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
