import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { SessionService } from '../shared/session.service';
import { UtilsService } from '../shared/utils.service';
import { Observable } from 'rxjs';
import {
  Breakpoints,
  BreakpointObserver,
  MediaMatcher
} from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { RouterService } from '../shared/router.service';
import { AccountModel } from '../models/account.model';
import { ManagedAccountService } from './managed-account.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserMessagesService } from '../user/user-messages/user-messages.service';
import {AuthService} from "../shared/auth.service";
import { AppcuesService } from '../services/appcues.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  @Input() sidenav: any;
  mobileQuery: MediaQueryList;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  filteredAccounts: AccountModel[];
  accountSearch: string;

  private readonly _mobileQueryListener: () => void;

  constructor(
    public sessionService: SessionService,
    public app: AppService,
    public utils: UtilsService,
    public routerService: RouterService,
    private auth: AuthService,
    private breakpointObserver: BreakpointObserver,
    private managedAccountService: ManagedAccountService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    public userMessagesService: UserMessagesService, // Used to display unread messages count
    private appcuesService: AppcuesService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  getAccountName() {
    return this.app.account.name +
    (this.app.isAuthenticated && this.mobileQuery.matches
      ? ' (' + this.app.account.subscription + ')'
      : '');
  }

  /**
   * Signs the user out of their current session.
   *
   * @return {void} No return value.
   */
  onSignOut(): void {
    this.auth.signOut();
  }

  onSearchAccounts() {
    this.managedAccountService
      .query({ search: this.accountSearch, order: 'asc' })
      .subscribe((response) => {
        this.filteredAccounts = response.data;
      });
  }

  openAccountSwitcher() {
    this.managedAccountService.query({ order: 'asc' }).subscribe((response) => {
      const excludeMe = response.data.filter(
        (account) => account.id !== this.app.account.id
      );
      this.filteredAccounts = excludeMe;
    });
  }

  switchAccount(id: number) {
    this.managedAccountService.switch(id).subscribe((response) => {
      this.app.account.apply(response.data.account);
      this.app.userAccountLink.apply(response.data.user_account_link);

      this.appcuesService.onIdentifyGroup(this.app.account.name, {
        name: this.app.account.name,
        role: this.app.userAccountLink.permissions,
        industry: this.app.account.industry,
        address: this.app.account.address,
        suburb: this.app.account.suburb,
        city: this.app.account.city,
        postal_code: this.app.account.postal_code,
        country: this.app.account.country
      });

      // window.location.reload();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(this.location.path());
      });
    });
  }
}
