<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <a class="navbar-brand" routerLink="/reports/access-records">Sign In/Out
      Records</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="navbar-nav mr-auto">

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="onExport()">
            <mat-icon>cloud_download</mat-icon> Export {{ dataSource.selection.hasValue() ? '(' + dataSource.selection.selected.length + ') Selected' : 'All' }} to CSV
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to export Sign In/Out Records." style="min-width: 150px">
          <mat-icon>more_vert</mat-icon>
          List Menu/Export
        </button>

        <span class="spinner-container" *ngIf="(dataSource.loading | async)"
              appMarginRight>
        </span>
      </span>

      <button type="button" mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="chartsUtils.onOpenMasterCharts('Access Activities')" matTooltip="Click this button to open the charts.">
        <mat-icon>bar_chart</mat-icon> Charts
      </button>

      <button mat-raised-button class="mr-0 mr-lg-4 ml-1 my-1 float-right float-lg-none" (click)="onFilter()" matTooltip="Click this button to display filter options.">
        <mat-icon>filter_list</mat-icon> Filter
      </button>

    </div>
  </nav>

  <app-site-access-activities-charts-container [baseStorageKey]="'site-access-activities-ar'"></app-site-access-activities-charts-container>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created" matSortDirection="desc" matSortDisableClear>
      <!-- Record ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Toggle sorting by ID">
          #
        </th>
        <td mat-cell *matCellDef="let access_record">
          <b>{{ access_record.id }}</b>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by full name">
          Full Name
        </th>
        <td mat-cell *matCellDef="let access_record">
          <a (click)="onUserPublicView(access_record.hash)"
             matTooltip="View User Public Profile">
            <b>{{ access_record.contact_person }}</b>
          </a> <br>
          {{ access_record.email }} <br>
          {{ access_record.mobile }}
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="entity_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by company name">
          Company Name
        </th>
        <td mat-cell *matCellDef="let access_record">
          {{ access_record.entity_name || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by site name">
          {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
        </th>
        <td mat-cell *matCellDef="let access_record">
          <ng-container *ngIf="access_record.site_id">
            <ng-container *ngIf="access_record?.parent_site_id">
              <small style="display: block;">
                <a [routerLink]="utils.prepareSiteRelatedLink(access_record?.parent_site_id, null, '/edit/details')">
                  {{ access_record?.parent_site_name || '' }}
                </a>
              </small>
            </ng-container>
            <mat-icon *ngIf="access_record?.parent_site_id" style="font-size: 16px;">subdirectory_arrow_right</mat-icon>
            <a [routerLink]="utils.prepareSiteRelatedLink(access_record?.site_id, access_record?.parent_site_id, '/edit/details')">
              {{ access_record.site_name || '' }}
            </a>
          </ng-container>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by type">
          Type
        </th>
        <td mat-cell *matCellDef="let access_record">{{ access_record.type }}
        </td>
      </ng-container>

      <!-- Method Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by QR Code">
          Sign-In/Out Method
        </th>
        <td mat-cell *matCellDef="let access_record">
          <span [matTooltip]="access_record.device_id">{{ access_record.method }}</span>
        </td>
      </ng-container>

      <!-- Date Created Column -->
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date Created
        </th>
        <td mat-cell *matCellDef="let access_record">
          {{ (access_record.date_created * 1000) | timezoneConvertLong}}
        </td>
      </ng-container>

      <!-- Date Created (UTC) Column -->
      <ng-container matColumnDef="date_created_UTC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Toggle sorting by date">
          Date Created (UTC)
        </th>
        <td mat-cell *matCellDef="let access_record">
          {{ (access_record.date_created * 1000) | timezoneConvertUTCLong}}
        </td>
      </ng-container>

      <ng-container matColumnDef="export">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let access_record">

          <button type="button" mat-button (click)="composeNewMessage(access_record.user_id)" matTooltip="Click this button to send a message to the user.">
            Send Message
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
