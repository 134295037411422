import { Injectable, ElementRef, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import * as CONST from '../app.const';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { QuickActionsComponent } from './quick-actions/quick-actions.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { QUICK_ACTIONS_OVERLAY, QUICK_ACTIONS_STRUCT } from '../shared/quick-actions/quick-actions.const';
import { ApiRequestService } from './api-request.service';
import { IndustriesModel } from "../admin/industries/industries.model";
import { TradeModel } from "../admin/trades/trades.model";
import { TaskAnalysesRevisedWorkflowModel } from "../models/task-analyses-revised-workflow.model";

// Import the default language file. It is replaced when the app is compiled.
import * as languageJsonFile from 'src/assets/languages/default.json';
import * as _ from 'lodash'; // Lodash is used to dynamically access properties of the default language file.
import { HttpParams } from '@angular/common/http';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  toastClass = '';

  toastMessage = '';

  modalTitle = '';
  modalContent = '';
  modalOkBtn: () => void;

  constructor(
    public dialog: MatDialog,
    private overlay: Overlay,
    private injector: Injector,
    private router: Router,
    private location: Location,
    private api: ApiRequestService
  ) { }

  /**
   * Show toast messages for 5 seconds at the bottom of the screen.
   *
   * @param message The message to be displayed.
   */
  showToast(message: string): void {
    // update the message
    this.toastMessage = message;
    // show the toast
    this.toastClass = 'show';
    // wait and dismiss
    setTimeout(() => {
      this.toastClass = '';
    }, 5000);
  }

  /**
   * Shows a modal with a title, content and optionally ok button.
   *
   * @param title
   * @param content Body text of the modal.
   * @param okBtn Function which runs on pressing the 'OK' button.
   *
   */
  showModal(title: string, content: string, okBtn?: () => void): void {
    // set the modal data
    this.modalTitle = title;
    this.modalContent = content;
    this.modalOkBtn = okBtn;

    try {
      // Causing an error in IE/Edge Legacy
      $('.modal').modal({
        backdrop: 'static'
      });
    }
    catch (err) {
      // IE/Edge Legacy Fix
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1) {
        this.modalOkBtn = () => {
          $('.modal').hide();
        };
        $('.modal').show();
      }
    }
    finally {
      // optional
    }
  }

  showFormValidationError(additionalMessage: string = '') {
    this.showModal('Validation Failed', '<p>Please make sure you enter all the required fields denoted with an asterisk *. </p>' + additionalMessage);
  }

  /**
   * Shows a Material Design Dialog.
   *
   * @param component The Angular Component to be shown.
   * @param [data] Data to be passed to the Component as MAT_DIALOG_DATA.
   * @param [dialogOptions]
   * @param [callback] Function which runs after dialog closes.
   */
  showComponentDialog(
    component: any,
    data: any = {},
    dialogOptions: MatDialogConfig = {},
    callback?: any
  ): Promise<any> {
    if (!dialogOptions) {
      dialogOptions = {
        width: '1024px',
        // height: '600px',
        // maxHeight: '600px',
        data: data,
        autoFocus: false
        // closeOnNavigation: false,
      };
    } else {
      if (!dialogOptions['width']) {
        dialogOptions['width'] = '1024px';
      }

      dialogOptions['data'] = data;
      dialogOptions['autoFocus'] = false;
    }

    const dialog = this.dialog.open(component, dialogOptions);

    return dialog.afterClosed().toPromise().then((result) => {
      if (typeof callback === 'function') {
        callback(result);
      }
      return result;
    });
  }

  showQuickActions(elementRef: ElementRef, text: string, buttons: { text: string, handler: any }[] = []) {
    // Create the overlay
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(elementRef)
        .withPositions([{
          overlayX: 'start',
          overlayY: 'top',
          originX: 'start',
          originY: 'bottom'
        }]),
      hasBackdrop: true, // Enable the backdrop.
      backdropClass: 'cdk-overlay-transparent-backdrop' // Use a transparent backdrop.
    });

    // Create the injector tokens to pass data into the component portal.
    const injectorTokens = new WeakMap();
    injectorTokens.set(QUICK_ACTIONS_OVERLAY, overlayRef);
    injectorTokens.set(QUICK_ACTIONS_STRUCT, {
      text: text,
      buttons: buttons
    });
    const portalInjector = new PortalInjector(this.injector, injectorTokens);

    // Create the component portal that will automatically dismiss when the backdrop is clicked.
    const quickActionsComponentPortal = new ComponentPortal(QuickActionsComponent, null, portalInjector);
    overlayRef.attach(quickActionsComponentPortal);
    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
  }

  /**
   * Get a "likelihood" list for hazard risk assessments.
   * @returns
   */
  getRiskAssessmentLikelihoodList() {
    return [
      this.getLangTerm('hazards.form.risk_assessments.likelihood[0]', 'Very unlikely to happen'),
      this.getLangTerm('hazards.form.risk_assessments.likelihood[1]', 'Unlikely to happen'),
      this.getLangTerm('hazards.form.risk_assessments.likelihood[2]', 'Possibly could happen'),
      this.getLangTerm('hazards.form.risk_assessments.likelihood[3]', 'Likely to happen'),
      this.getLangTerm('hazards.form.risk_assessments.likelihood[4]', 'Very likely to happen')
    ];
  }

  /**
   * Get a "severity" list for hazard risk assessments.
   * @returns
   */
  getRiskAssessmentSeverityList() {
    return [
      this.getLangTerm('hazards.form.risk_assessments.severity[0]', 'Superficial'),
      this.getLangTerm('hazards.form.risk_assessments.severity[1]', 'Minor'),
      this.getLangTerm('hazards.form.risk_assessments.severity[2]', 'Moderate'),
      this.getLangTerm('hazards.form.risk_assessments.severity[3]', 'Major'),
      this.getLangTerm('hazards.form.risk_assessments.severity[4]', 'Catastrophic')
    ];
  }

  /**
   * Gets risk assessment text
   * @param likelihood The IRA likelihood of a Hazard|Substance.
   * @param severity The IRA severity of a Hazard|Substance.
   * @example
   * > getRiskAssessmentText(1, 2);
   * < "Very Low"
   */
  getRiskAssessmentText(likelihood: number, severity: number): string {
    const weight: string = likelihood.toString() + severity.toString();

    if (['11', '12', '21'].find((value) => value === weight)) {
      return 'Very Low';
    }

    if (['13', '22', '31'].find((value) => value === weight)) {
      return 'Low';
    }

    if (
      ['14', '15', '23', '24', '25', '32', '33', '34', '41', '42', '43', '51', '52'].find(
        (value) => value === weight
      )
    ) {
      return 'Moderate';
    }

    if (['35', '44', '53'].find((value) => value === weight)) {
      return 'High';
    }

    if (['45', '54', '55'].find((value) => value === weight)) {
      return 'Critical';
    }

    return 'Not Set';
  }

  getRiskAssessmentBootstrapColor(likelihood: number, severity: number) {

    const riskAssessment = this.getRiskAssessmentText(likelihood, severity);

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  /**
   * @returns [0, 1, 2, ..., 23]
   */
  getHoursInDay(): number[] {
    const hours = [];
    for (let hour = 0; hour <= 23; hour++) {
      hours.push(hour); // < 10 ? '0'+hour : hour
    }
    return hours;
  }

  /**
   * @returns [0, 1, 2, ..., 59]
   */
  getMinutesInHour(): number[] {
    const minutes = [];
    for (let minute = 0; minute <= 59; minute++) {
      minutes.push(minute); // < 10 ? '0'+minute : minute
    }
    return minutes;
  }

  /**
   * Maps month number to short name.
   *
   * @param month Month number such that 0 = Jan, 1 = Feb, ..., 11 = Dec
   * @returns Short name of the month. E.g. Jan, Feb.
   * @example
   * > getShortMonth(2);
   * < 'Mar'
   */
  getShortMonth(month: number): string {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ][month];
  }

  /**
   * Converts Unix time into a readable format.
   *
   * @param value Unix time.
   * @returns String representation of a timestamp. E.g. "Aug 20, 2019 14:27".
   * @example
   * > convertDate(1554017707);
   * < "Mar 31, 2019 20:35"
   */
  convertDate(value: number): string {
    const date = new Date(value * 1000);

    return (
      this.getShortMonth(date.getMonth()) +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear() +
      ' ' +
      this.zerofy(date.getHours()) +
      ':' +
      this.zerofy(date.getMinutes())
    );
  }

  /**
   * Converts a datetime string in ISO format to a human readable format.
   *
   * @param isoDate Date string in ISO format.
   * @returns String representation of a timestamp. E.g. "Aug 20, 2019 14:27".
   * @example
   * > convertISODate('2019-08-20T02:31:49.876Z')
   * < "Aug 20, 2019 14:27"
   */
  convertISODate(isoDate: string): string {
    const date = new Date(isoDate);

    return (
      this.getShortMonth(date.getMonth()) +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear() +
      ' ' +
      this.zerofy(date.getHours()) +
      ':' +
      this.zerofy(date.getMinutes())
    );
  }

  /**
   * @example
   * > zerofy(10);
   * < 10
   */
  zerofy(number: number): number | string {
    return number < 10 ? '0' + number : number;
  }

  /**
   * Converts Unix timestamp to human readable format.
   *
   * @param value unix timestamp
   * @example
   * > unixToDate(1554017707);
   * < "Mar 31, 2019"
   */
  unixToDate(value: number): string {
    const date = new Date(value * 1000);

    return (
      this.getShortMonth(date.getMonth()) +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear()
    );
  }

  /**
   *
   * @param dateString
   * convert a date string to unix timestamp.
   * e.g. "Feb 13, 2019 9:4" > 1550001840
   */
  dateStringToUnix(dateString: string): number {
    const date = Date.parse(dateString);
    return date / 1000;
  }

  /**
   * Converts seconds into a more readable format.
   *
   * @param elapsedTime in seconds
   * @example
   * > unixSecondsToElapsedDateTime(333);
   * < "6m 33s "
   */
  unixSecondsToElapsedDateTime(elapsedTime: number): string {
    const seconds: number = elapsedTime % 60; // remaining seconds
    let minutes: number = elapsedTime / 60; // minutes
    let hours: number = minutes / 60; // hours
    const days: number = hours / 24; // days

    minutes = minutes % 60; // reassign minutes
    hours = hours % 24; // reassign hours

    return (
      (days >= 1 ? Math.round(days) + 'd ' : '') +
      (hours >= 1 ? Math.round(hours) + 'h ' : '') +
      (minutes >= 1 ? Math.round(minutes) + 'm ' : '') +
      (seconds >= 1 ? Math.round(seconds) + 's ' : '')
    );
  }

  /**
   * Get the current environment as defined in app.const.ts
   * @example
   * > getEnvModeAsText()
   * < "LOCAL"
   */
  getEnvModeAsText(): string {
    return CONST.getEnvModeAsText();
  }

  /**
   * Retrieves the current version of the application.
   *
   * @return {string} The application version as a string.
   */
  getAppVersion(): string {
    return CONST.getAppVersion();
  }

  /**
   * Check if this is the production environment.
   */
  isProduction(): boolean {
    return CONST.isProduction();
  }

  /**
   * Filters an array of objects by unique ID.
   * @returns Entities with distinct IDs.
   * @example
   * > JSON.stringify(unique([{id: 1}, {id: 1}, {id: 2}]))
   * < '[{"id":1},{"id":2}]'
   */
  unique(entities: any[]): any[] {
    const result = [];
    const map = new Map();
    for (const entity of entities) {
      if (!map.has(entity.id)) {
        map.set(entity.id, true); // set any value to Map
        result.push(entity);
      }
    }
    return result;
  }

  /**
   * Converts a File object into a base64 string.
   */
  getBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async getIndustries(): Promise<IndustriesModel[]> {
    return await new Promise((resolve, reject) => {
      this.api.makeRequest('get', `v2/industries`, {}, {
        order_by: 'name',
        order: 'asc'
      })
        .then((response: { data: IndustriesModel[] }) => {
          resolve(response.data);
        })
        .catch((_errorResponse) => {
          this.handleAPIErrors(_errorResponse);
          reject([]);
        });
    });
  }

  async getTrades(): Promise<TradeModel[]> {
    return await new Promise((resolve, reject) => {
      this.api.makeRequest('get', `v2/trades`, {}, {
        order_by: 'name',
        order: 'asc'
      })
        .then((response: { data: TradeModel[] }) => {
          resolve(response.data);
        })
        .catch((_errorResponse) => {
          this.handleAPIErrors(_errorResponse);
          reject([]);
        });
    });
  }

  async getWorkflowSteps(ta_revised_id: number, site_id: number = 0): Promise<TaskAnalysesRevisedWorkflowModel[]> {
    return await new Promise((resolve, reject) => {

      if(site_id > 0) {
        this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}/workflows`, {}, {
          order_by: 'step_no',
          order: 'asc',
          site_id: site_id,
          all_records: true
        })
          .then((response: { data: TaskAnalysesRevisedWorkflowModel[] }) => {
            resolve(response.data);
          })
          .catch((_errorResponse) => {
            this.handleAPIErrors(_errorResponse);
            reject([]);
          });
      } else {
        this.api.makeRequest('get', `v2/task-analyses-revised/${ta_revised_id}/workflows`, {}, {
          order_by: 'step_no',
          order: 'asc',
          all_records: true
        })
          .then((response: { data: TaskAnalysesRevisedWorkflowModel[] }) => {
            resolve(response.data);
          })
          .catch((_errorResponse) => {
            this.handleAPIErrors(_errorResponse);
            reject([]);
          });
      }

    });
  }

  /**
   * Use this to refresh the page or navigate to a different page.
   * This is typically used with edit pages that redirects the user to edit a newly created record.
   *
   * @param redirectTo The path to redirect to, broken up in string array pieces. You can omit this to just refresh the current page.
   * @param queryParams The query params to pass along with the redirect.
   */
  refreshPage(redirectTo?: any[], queryParams: any = {}) {
    // Redirect the user.
    return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.redirectPage(redirectTo || [this.location.path()], queryParams);
    });
  }

  /**
   * Use this if you need to redirect the user to a different page.
   * Auto redirect is only used by the SigninComponent.
   *
   * @param redirectTo The path to redirect to, broken up in string array pieces.
   * @param queryParams The query params to pass along with the redirect.
   * @param includeAutoRedirect Include the current url as the redirect path if the user should be returned to the currently viewed path. This is typically used if the user is unauthenticated.
   */
  redirectPage(redirectTo?: any[], queryParams: any = {}, includeAutoRedirect: boolean = false) {
    // Automatically redirect the user back to where they were.
    if ( includeAutoRedirect ) {
      // Get the full relative path from the browser e.g. /feature?object=x&id=y
      const path = this.location.path();
      // Split the paths into two parts. The navigated route and query params.
      const path_parts = path.split('?'); // Query params starts with ? and is concatenated with &.
      // Set the redirect path.
      queryParams.redirect = path_parts[0];
      // Let's extract the query params.
      if ( typeof path_parts[1] != 'undefined' && path_parts[1] ) {
        // Split the query params with &. It will contain an array of "key=value" pairs.
        const query_params: string[] = path_parts[1].split('&');
        // If we have query params available, split the key and value from each other.
        if ( query_params.length > 0 ) {
          // Loop through all "key=value" pairs and split their key value values.
          for( let i = 0; i < query_params.length; i++ ) {
            // Split key value pairs with =.
            const query_param_set = query_params[i].split('=');
            // Check if the key is present. It can be preserved with an empty value.
            if ( typeof query_param_set[0] != 'undefined' && query_param_set[0] ) {
              // Store the key and value data in the queryParams array for preservation. It is ok to have empty values.
              queryParams[query_param_set[0]] = (typeof query_param_set[1] != 'undefined') && query_param_set[1] ? query_param_set[1] : '';
            }
          }
        }
      }
    }
    // Redirect the user.
    return this.router.navigate(redirectTo, {
      queryParams: queryParams
    });
  }

  /**
   * This can be used to redirect the user back to the page that was previously active.
   */
  goBack() {
    // Redirect the user.
    this.location.back();
  }

  /**
   * Removes a query param from the browser location's query param list.
   * @param query_param_key The query param to remove.
   * Credit: https://stackoverflow.com/a/70149164/10688654
   */
  removeBrowserLocationQueryParam(query_param_key: string = '') {
    const [path, query] = this.location.path().split('?');
    const params = new HttpParams({ fromString: query });
    this.location.replaceState(path, params.delete(query_param_key).toString());
  }

  getUserRoles() {
    return [
      'Administrator',
      'Employee'
    ];
  }

  /**
   * Handles API errors by extracting and displaying error messages.
   *
   * @param {any} errorResponse - The error response from the API.
   * @return {void} - This method does not return anything.
   */
  handleAPIErrors(errorResponse: any): void {
    // Define a basic error message variable.
    let errorMessage: string = '';
    // Check if the error response contains any errors.
    if ( errorResponse.error && errorResponse.error.errors ) {
      // Loop and extract all errors.
      Object.keys(errorResponse.error.errors).forEach((errorField: string): void => {
        Object.keys(errorResponse.error.errors[errorField]).forEach((errorEntry: string): void => {
          errorMessage += '<li>' + errorResponse.error.errors[errorField][errorEntry] + '</li>';
        });
      });
    }
    // Define a base error message.
    let baseMessage: string = '';
    // Check if an error message were specified.
    if ( errorResponse.error && errorResponse.error.message ) {
      baseMessage += errorResponse.error.message;
    } else {
      // Check the status text.
      if ( errorResponse.statusText ) {
        baseMessage += errorResponse.statusText;
      } else {
        // Check the base error message. It may include API controller names.
        if ( errorResponse.message ) {
          baseMessage += errorResponse.message;
        }
      }
    }
    // Add all errors to the basic error message.
    errorMessage = '<b>' + baseMessage + '</b><br><br><ul>' + errorMessage + '</ul>';
    // Show the error message in a popup dialog.
    this.showModal('Error Message', errorMessage);
  }

  /**
   * Opens the provided URL in a new tab.
   * @param url The URL to open in a new tab.
   */
  openUrl(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Downloads the provided URL to the browser.
   * @param url The URL to download.
   */
  downloadUrl(url: string) {
    // TBD: Add code to download the provided file.
    // Experimental Code:
    window.location.assign(url);
    // Another Solution:
    // let downloadAnchor = document.createElement('a');
    // downloadAnchor.href = url;
    // downloadAnchor.download = url;
    // downloadAnchor.target = '_blank';
    // document.body.appendChild(downloadAnchor);
    // downloadAnchor.click();
    // document.body.removeChild(downloadAnchor);
  }

  /**
   * This returns a list of site statuses extracted form a JSON object language file.
   */
  getSiteTypes() {
    return this.getLangTerm('sites.list.site-types', []);
  }

  /**
   * This returns a list of site statuses extracted from a JSON object language file.
   */
  getSiteStatuses() {
    return this.getLangTerm('sites.list.site-statuses', []);
  }

  getSiteRiskStatusTextColor(site_risk_status: string) {
    switch (site_risk_status.toLowerCase()) {
      case 'very low':
        return 'text-green';
      case 'low':
        return 'text-blue';
      case 'moderate':
        return 'text-orange';
      case 'high':
      case 'critical':
        return 'text-red';
      default:
        return '';
    }
  }

  getSiteRiskStatusChipColor(site_risk_status: string) {
    switch (site_risk_status.toLowerCase()) {
      case 'very low':
        return 'chip-success';
      case 'low':
        return 'chip-info';
      case 'moderate':
        return 'chip-warning';
      case 'high':
        return 'chip-warning-secondary';
      case 'critical':
        return 'chip-danger';
      default:
        return '';
    }
  }

  getSiteTAStatusTextColor(site_ta_status: string) {
    switch (site_ta_status.toLowerCase()) {
      case 'acknowledged':
      case 'approved':
      case 'authorized':
        return 'text-green';
      case 'pending':
        return 'text-orange';
      case 'required':
        return 'text-red blink';
      default:
        return '';
    }
  }

  getSiteTAUserCheck(site_users: any, user_id: number) {
    let check = false;
    if (site_users && site_users.length > 0) {
      site_users.forEach((user, index) => {
        if (user.id == user_id) {
          check = true;
          return check;
        }
      });
    }

    return check;
  }

  getSiteTAUserStatus(site_users: any) {
    let status = true;
    if (site_users && site_users.length > 0) {
      site_users.forEach((user, index) => {
        if (user.pivot.acknowledged == 0) {
          status = false;
          return status;
        }
      });
    } else {
      status = false;
    }

    return status;
  }

  getSiteTAUserStatusRequired(site_users: any, user_id: number) {
    let required = false;
    if (site_users && site_users.length > 0) {
      site_users.forEach((user, index) => {
        if (user.pivot.acknowledged == 0 && user.id == user_id) {
          required = true;
          return required;
        }
      });
    }

    return required;
  }

  getSafetyObservationCategories() {
    return [
      'Safety Mentions',
      'Opportunities for Improvements',
      'Critical Items',
      'Safe Act',
      'Unsafe Act',
      'Unsafe Condition',
      'Safety Rule Violation'
    ];
  }

  /**
   * Retrieves the list of safety observation types.
   *
   * @returns {string[]} An array containing safety observation types.
   *
   * API: app/Services/SafetyObservationsService.php
   * Mobile App: src/app/services/utils.service.ts
   */
  getSafetyObservationTypes(): string[] {
    return [
      'Access',
      'Accessibility',
      'Accommodation',
      'Electrical Hazard',
      'Environmental',
      'Excavation',
      'Fire Safety Equipment and Procedures',
      'General Equipment',
      'Lighting',
      'Non-compliance',
      'Personal Protective Equipment',
      'Public Safety',
      'Security',
      'Storage / Containment',
      'Tidiness / Cleanliness',
      'Traffic Management',
      'Transport',
      'Vehicles',
      'Work Environment',
      'Working at Heights',
      'Other'
    ];
  }

  getSafetyObservationPriorities() {
    return [
      'Not Applicable',
      'Very Low',
      'Low',
      'Moderate',
      'High',
      'Critical'
    ];
  }

  getSafetyObservationStatuses() {
    return [
      'Open',
      'Review in Progress',
      'Closed: No Action',
      'Closed: With Action',
      'Archived'
    ];
  }

  getSafetyObservationStatusCssClasses(so_status: string) {
    switch (so_status) {
      case 'Open':
        return 'text-red blink';
      case 'Review in Progress':
        return 'text-blue';
      case 'Closed: No Action':
      case 'Closed: With Action':
      case 'Archived':
        return 'text-green';
      default:
        return '';
    }
  }

  /**
   * Get a list of priorities for corrective actions.
   * This need to be aligned with other risk levels.
   * @returns an array of priorities for corrective actions.
   */
  getCorrectiveActionPriorities() {
    return [
      'Low',
      'Medium',
      'High',
      'Critical'
    ];
  }

  /**
   * Check if the provided URL links to an image.
   * @param url The URL of the file to check.
   */
  isImage(url: string) {
    return url && url.match(/.(jpg|jpeg|png|gif|bmp)/i);
  }

  /**
   * Convert JSON string to JSON object.
   * @param jsonString The value of the json string to convert.
   */
  jsonParse(jsonString: any) {
    return JSON.parse(jsonString);
  }

  /**
   * By using lodash we can dynamically look for values in nested properties of JSON objects.
   * In this case we are loading a default language file that hosts terms for the software.
   * @param path The object path to use to try and get terms.
   * @param defaultValue The default value to get as a fallback.
   * @returns
   */
  getLangTerm(path: string, defaultValue: any) {
    return _.get(languageJsonFile, 'default.' + path, defaultValue);
  }

  // Get current timezone of location
  getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Get the URL of the knowledgebase.
   * @returns The URL to the knowledgebase.
   */
  getKnowledgebaseUrl() {
    return CONST.getKnowledgebaseUrl();
  }

  /**
   * Get the URL of the release notes for the software.
   * @returns The URL to the release notes.
   */
  getReleaseNotesUrl() {
    return CONST.getReleaseNotesUrl();
  }

  /**
   * Get the name of the app.
   * @returns The name of the app.
   */
  getAppName() {
    return CONST.getAppName();
  }

  /**
   * Get the URL to the mobile app in the app store for Android.
   * @returns The Android mobile app store URL.
   */
  getAndroidMobileAppStoreUrl() {
    return CONST.getAndroidMobileAppStoreUrl();
  }

  /**
   * Get the URL to the kiosk app in the app store for Android.
   * @returns The Android kiosk app store URL.
   */
  getAndroidKioskAppStoreUrl() {
    return CONST.getAndroidKioskAppStoreUrl();
  }

  /**
   * Get the URL to the mobile app in the app store for iOS.
   * @returns The iOS mobile app store URL.
   */
  getIosMobileAppStoreUrl() {
    return CONST.getIosMobileAppStoreUrl();
  }

  /**
   * Get the URL to the kiosk app in the app store for iOS.
   * @returns The iOS kiosk app store URL.
   */
  getIosKioskAppStoreUrl() {
    return CONST.getIosKioskAppStoreUrl();
  }

  /**
   * Get the URL to the privacy policy from the environment file.
   */
  getPrivacyPolicyUrl() {
    return CONST.getPrivacyPolicyUrl();
  }

  /**
   * Get the URL to the terms and conditions from the environment file.
   */
  getTermsAndConditionsUrl() {
    return CONST.getTermsAndConditionsUrl();
  }

  /**
   * Get the sales email from the environment files.
   * @returns The sales email address.
   */
  getSalesEmailAddress() {
    return CONST.getSalesEmailAddress();
  }

  /**
   * Get the support email from the environment files.
   * @returns The support email address.
   */
  getSupportEmailAddress() {
    return CONST.getSupportEmailAddress();
  }

  /**
   * Returns the upvoty link.
   *
   * @returns {string} The upvoty link.
   */
  getUpvotyLink(): string {
    return CONST.getUpvotyLink();
  }

  /**
   * Get the urgent notice URL from the environment files.
   */
  getUrgentNoticeUrl() {
    return CONST.getUrgentNoticeUrl();
  }

  /**
   * Prepares a site related link.This is primarily used when viewing or editing a site or related features.
   * @param site The site to create the link for.
   * @param action The action can be view or edit.
   * @param route The route sections that forms part of the route which is added at the tail end of the full route.
   */
  prepareSiteRelatedLink(id: number, parent_site_id: number, route: string) {
    // Prepare the base route.
    const route_base = parent_site_id ? `/sites/${parent_site_id}/children/${id}/${route}` : `/sites/${id}/${route}`;
    // Return the route.
    return route_base;
  }

  /**
   * Returns an array with Hazardous Substance types.
   */
  getHazardousSubstanceTypes() {
    return [
      'Gas',
      'Liquid',
      'Solid'
    ]
  }

  /**
   * Returns an array with measurement units.
   */
  getHazardousSubstanceUnitTypes() {
    return [
      'Kg',
      'L',
      'fl.oz',
      'gal.',
      'lb.',
      'oz.'
    ];
  }

  /**
   * Get the measurement unit based on the provided Hazardous Substance type.
   * @param hazardous_substance_type
   */
  getHazardousSubstanceUnitType(hazardous_substance_type: string) {
    // Set the measurement unit.
    let measurement_unit: string = '';
    // Find the measurement unit based on the Hazardous Substance type.
    switch ( hazardous_substance_type ) {
      case 'Solid':
        measurement_unit = 'Kg';
        break;
      case 'Liquid':
      case 'Gas':
        measurement_unit = 'L';
        break;
      default:
        // Do not change.
    }
    // Return the measurement unit.
    return measurement_unit;
  }

  /**
   * Use this method to validate a mobile number.
   * @param mobile The mobile number could have + prepended hence we are using strings.
   * @returns boolean True if the mobile number is valid. False if the mobile number is invalid.
   */
  isValidContactNumber(mobile: string) {
    // Regex to validate a mobile number.
    const regex = /^(\+?\d{1,3}[\s-]?)?\(?\d{1,4}\)?[\s-]?\d{1,4}[\s-]?\d{1,10}$/;
    // Return the result of the regex test.
    return regex.test(mobile);
  }

  /**
   * Use this method to validate an email address.
   * @param email The email address to validate.
   * @returns boolean True if the email address is valid. False if the email address is invalid.
   */
  isValidEmailAddress(email: string) {
    // Regex to validate an email address.
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Return the result of the regex test.
    return regex.test(email);
  }

  /**
   * Use this method to check if a URL is valid. URLs can both include and exclude the protocols.
   * @param url The URL to validate.
   * @returns boolean True if the URL is valid. False if the URL is invalid.
   */
  isValidURL(url: string) {
    // Regex to validate a URL.
    const urlRegex = /^((https?|ftp):\/\/)?([a-z0-9\-]+\.)+[a-z]{2,}(\/[a-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?$/i;
    // Return the result of the regex test.
    return urlRegex.test(url);
  }

  /**
   * Load an external script. This will automatically append the script to the body.
   * You can use the callback function if you need to implement features that depend on the script.
   * @param id The ID of the script to load.
   * @param url The URL of the script to load e.g. Google Maps.
   * @param callback The callback function to execute after the script has loaded.
   */
  public loadExternalScript(id: string, url: string, callback?: Function) {
    const scriptExists = document.getElementById(id);
    if ( !scriptExists ) {
      const body = <HTMLDivElement> document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.id = id;
      script.async = true;
      script.defer = true;
      body.appendChild(script);
      if ( callback ) {
        script.onload = () => {
          callback();
        };
      }
    } else {
      callback();
    }
  }

  /**
   * Retrieves a list of incident types.
   *
   * @return {string[]} - An array of incident types.
   */
  getIncidentTypes(): string[] {
    return [
      'Environment',
      'Illness',
      'Injury',
      'Machinery',
      'Mobile Plant',
      'Motor Vehicle',
      'Near Miss',
      'Production Management',
      'Property Damage',
      'Security',
      'Other'
    ];
  }

  /**
   * Retrieves a list of incident statuses.
   *
   * @return {string[]} - An array of incident statuses.
   */
  getIncidentStatuses(): string[] {
    return [
      'Reported',
      'Assigned',
      'In Progress',
      'Submitted',
      'Completed'
    ];
  }

  /**
   * Normalizes query parameters.
   *
   * @param {Object} params - The query parameters to be normalized.
   * @return {Object} - The normalized query parameters.
   */
  normalizeQueryParams(params: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = Array.isArray(value) ? value.join(',') : value;
      return acc;
    }, {});
  }

  /**
   * Returns an array of document types for accounts.
   *
   * The list of document types must be included in the list in the API (App/Models/Folder::getDocumentTypes()).
   *
   * @return {string[]} An array of document types for accounts.
   */
  public getDocumentTypesForAccounts(): string[] {
    return [
      'Certificate',
      'Instructions',
      'Insurance',
      'Log',
      'Manual',
      'Meeting',
      'Minutes',
      'Permit',
      'Plan',
      'Policy',
      'Procedure',
      'Record',
      'Report',
      'Training',
      'Other'
    ];
  }

  /**
   * Retrieves the available document types for users.
   *
   * This method returns an array of document types that are available for users.
   * The list of document types must be included in the list in the API (App/Models/Folder::getDocumentTypes()).
   *
   * @returns {string[]} - An array of document types available for users.
   */
  public getDocumentTypesForUsers(): string[] {
    return [
      'Certificate',
      'CV',
      'Degree',
      'Diploma',
      'Drivers License',
      'External Training Record',
      'Forkhoist License',
      'In-house Training Record',
      'Induction',
      'License Other',
      'Passport',
      'Police Vetting',
      'Policy',
      'Procedure',
      'Work Health and Safety',
      'Work Visa',
      'Workers Compensation',
      'Other'
    ];
  }

  /**
   * Retrieves a list of maintenance and safety checks for tools.
   *
   * @returns {string[]} - Array containing the list of maintenance and safety checks.
   */
  getToolsMaintenanceAndSafetyChecksList(): string[] {
    return [
      'Routine Maintenance',
      'Scheduled Inspections',
      'Periodic Testing and Tagging',
      'Safety Audits',
      'Equipment Calibration',
      'Preventive Maintenance Checks',
      'Quality Control Checks',
      'Regulatory Compliance Checks',
      'Emergency Preparedness Assessments',
      'Asset Integrity Assessments',
      'Pressure Test',
      'Safety Harness/Lanyard inspection',
      'Fire Extinguisher Annual Service',
      'LPG Bottle inspection',
      'First Aid Kit inspection'
    ];
  }
}
