import { HttpClientTestingModule } from '@angular/common/http/testing';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { EditorModule } from '@tinymce/tinymce-angular';
import { environment } from 'src/environments/environment';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';

/**
 *
 * Before building, make sure the correct environment is set in the getEnvMode()
 * function below.
 *
 * Build the app for the dev and staging environment using the following command:
 *
 * $ ng build
 *
 * Build the app for the production environment using:
 * AOT is automatically executed with --prod.
 *
 * $ ng build --prod
 *
 */

/**
 * Retrieves the current environment mode as a text string.
 *
 * @return {string} The mode of the environment, typically indicating development, production, etc.
 */
export function getEnvModeAsText(): string {
  return environment.MODE;
}

/**
 * Retrieves the current application version.
 *
 * @return {string} The version of the application as defined in the environment configuration.
 */
export function getAppVersion(): string {
  return environment.APP_VERSION;
}

/**
 * Determines if the current environment is set to production.
 *
 * @return {boolean} Returns true if the application is running in a production environment, otherwise false.
 */
export function isProduction(): boolean {
  return environment.production;
}

export let SERVICE_URL = environment.SERVICE_URL;
export let LARAVEL_API_URL = environment.LARAVEL_API_URL;
export let API_URL = environment.API_URL;

/**
 * Get the URL of the knowledgebase.
 * @returns The name of the app.
 */
export function getKnowledgebaseUrl() {
  return environment.PROJECT_KNOWLEDGEBASE_URL;
}

/**
 * Get the URL of the release notes for the software.
 * @returns The name of the app.
 */
export function getReleaseNotesUrl() {
  return environment.PROJECT_RELEASE_NOTES_URL;
}

/**
 * Get the name of the app.
 * @returns The name of the app.
 */
export function getAppName() {
  return environment.APP_NAME;
}

/**
 * Get the URL to the mobile app in the app store for Android.
 * @returns The Android mobile app store URL.
 */
export function getAndroidMobileAppStoreUrl() {
  return environment.ANDROID_STORE_URL_MOBILE;
}

/**
 * Get the URL to the kiosk app in the app store for Android.
 * @returns The Android kiosk app store URL.
 */
export function getAndroidKioskAppStoreUrl() {
  return environment.ANDROID_STORE_URL_KIOSK;
}

/**
 * Get the URL to the mobile app in the app store for iOS.
 * @returns The iOS mobile app store URL.
 */
export function getIosMobileAppStoreUrl() {
  return environment.IOS_STORE_URL_MOBILE;
}

/**
 * Get the URL to the privacy policy from the environment file.
 */
export function getPrivacyPolicyUrl() {
  return environment.PRIVACY_POLICY_URL;
}

/**
 * Get the URL to the terms and conditions from the environment file.
 */
export function getTermsAndConditionsUrl() {
  return environment.TERMS_CONDITIONS_URL;
}

/**
 * Get the URL to the kiosk app in the app store for iOS.
 * @returns The iOS kiosk app store URL.
 */
export function getIosKioskAppStoreUrl() {
  return environment.IOS_STORE_URL_KIOSK;
}

/**
 * Get the sales email from the environment files.
 * @returns The sales email address.
 */
export function getSalesEmailAddress() {
  return environment.SALES_EMAIL;
}


/**
 * Get the urgent notice link from the environment files.
 * @returns The sales email address.
 */
export function getUrgentNoticeUrl() {
  return environment.URGENT_NOTICE_URL;
}

/**
 * Get the support email from the environment files.
 * @returns The support email address.
 */
export function getSupportEmailAddress() {
  return environment.SUPPORT_EMAIL;
}

/**
 * Retrieves the Upvoty link.
 *
 * @returns {string} The Upvoty link.
 */
export function getUpvotyLink(): string {
  return environment.UPVOTY_LINK;
}

export const TEST_IMPORTS = [
  MatIconModule,
  FormsModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  HttpClientTestingModule,
  MatListModule,
  MatFormFieldModule,
  RouterTestingModule,
  MatCheckboxModule,
  MatMenuModule,
    MatOptionModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NoopAnimationsModule,
  MatSortModule,
  PipesModule,
  MatTabsModule,
  EditorModule,
  MatExpansionModule,
  MatSidenavModule,
  DirectivesModule,
  MatSlideToggleModule,
  MatTooltipModule
];

export const COMPONENT_MODULES = [
  MatIconModule,
  FormsModule,
  MatButtonModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  RouterTestingModule,
  MatSortModule
];

export const TEST_PROVIDERS = [
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: {} }
];

export const MATERIAL_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatButtonModule,
  MatInputModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatListModule,
  MatCheckboxModule,
  MatDialogModule,
  MatBadgeModule,
  MatMenuModule,
  MatRadioModule,
  MatCardModule,
  MatTabsModule,
  MatDividerModule,
  MatChipsModule
];
