import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { UtilsService } from '../shared/utils.service';
import {ApiRequestService} from "../shared/api-request.service";
import {AuthService} from "../shared/auth.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  adminRoutesSubscription: Subscription;

  activeRoute: string = '';

  constructor(
    private router: Router,
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // This is used to construct the breadcrumbs, do not change.
    this.activeRoute = this.router.url;
    this.adminRoutesSubscription = this.router.events.subscribe((event: Event) => {
      this.activeRoute = this.router.url;
    });
  }

  private getBreadcrumbsName(name: string) {
    switch(name.toLowerCase()) {
      case "task-analyses-revised":
        return "Task Analyses Templates";
      case "work-permits":
        return "Work Permits";
      default:
        return name;
    }
  }

  getBreadcrumbs() {
    const parts = this.activeRoute.split('/').filter((part) => (part != ''));
    let reconstructedMenu = [];
    let reconstructedRoute: string = '';
    parts.forEach((part: string) => {
      reconstructedRoute += '/' + part;
      if ( !Number(part) && part != 'new' ) {
        reconstructedMenu.push({
          name: this.getBreadcrumbsName(part),
          uri: reconstructedRoute
        });
      }
    });
    return reconstructedMenu;
  }

  ngOnDestroy() {
    this.adminRoutesSubscription.unsubscribe();
  }

  /**
   * Manually invoke the artisan command to sign all onsite users out.
   */
  runAutoSiteSignOut() {
    // Get confirmation.
    this.utils.showModal('Sign Onsite Users Out', 'Are you sure you want to sign all onsite users out?', () => {
      this.api.makeRequest('get', 'v2/admin/auto-site-sign-out', {})
        .then((response) => {
          this.utils.showToast('The command for Automatic ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ' Sign Out has been run.');
        });
    });
  }

  /**
   * Send a request to the API to reset the database.
   */
  onResetDatabase() {
    // Check if the environment is production.
    if ( this.utils.isProduction() ) {
      this.utils.showModal('Reset Database', 'You cannot reset the production database.');
      return;
    }
    // Prompt for confirmation.
    this.utils.showModal('Reset Database', '<p>Are you sure you want to reset the database?</p> <ul><li>This cannot be undone.</li> <li>You will be logged out and notified when it is done.</li> <li>Keep your browser window open until it is done.</li></ul> <p>It will take a minute or two to fully complete. You can watch the progress in the Slack application logs. If it gets stuck, ask a developer to reset it manually.</p>', () => {
      // Make the request to reset the database.
      this.api.makeRequest('post', 'v2/admin/reset-database', {})
        .then((response) => {
          this.utils.showModal('Database Reset Success', response.message);
        })
        .catch((errorResponse) => {
          this.utils.showModal('Database Reset Failed', errorResponse.error.message);
        });

      // Sign the user out.
      this.authService.signOut('You will be notified when the database reset is complete.');
    });
  }

}
